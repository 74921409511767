import Tabs from '@/components/Tabs'
import { ProfileAuthorized } from '@/lib/types'
import AccountEmailSettings from './AccountEmailSettings'
import AccountProfileSettings from './AccountProfileSettings'
import AccountVerificationSettings from './AccountVerificationSettings'

type AccountSettingsTabsProps = {
  profile: ProfileAuthorized
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getProfile?: () => void
  inSettings?: boolean
  profilePublicKey: string
}

export default function AccountSettingsTabs({
  profile,
  setIsOpen,
  getProfile,
}: AccountSettingsTabsProps) {
  const profileComponent = (
    <AccountProfileSettings
      profile={profile}
      setIsOpen={setIsOpen}
      getProfile={getProfile}
    />
  )

  const verificationsComponent = <AccountVerificationSettings />

  // TO DO: implement tabs for release settings
  // TO DO: implement tabs for hub settings
  // TO DO: implement tabs for earnings settings
  // TO DO: email preferences tab
  const emailPreferences = (
    <AccountEmailSettings
      profile={profile}
      setIsOpen={setIsOpen}
      getProfile={getProfile}
    />
  )

  const content = {
    profile: profileComponent,
    verifications: verificationsComponent,
    emailPreferences,
  }

  return (
    <div className="w-full ">
      <Tabs content={content} inSettings={true} />
    </div>
  )
}
