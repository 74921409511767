import { createContext, useState } from 'react'

interface PopUpContextValues {
  showPopUp: boolean
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>
  forceOpen: boolean
  setForceOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PopUpContext = createContext<PopUpContextValues>({
  showPopUp: false,
  setShowPopUp: () => undefined,
  forceOpen: false,
  setForceOpen: () => undefined,
})

const PopUpContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [showPopUp, setShowPopUp] = useState(true)
  const [forceOpen, setForceOpen] = useState(false)

  return (
    <PopUpContext.Provider
      value={{
        showPopUp,
        setShowPopUp,
        forceOpen,
        setForceOpen,
      }}
    >
      {children}
    </PopUpContext.Provider>
  )
}

export default {
  Context: PopUpContext,
  Provider: PopUpContextProvider,
}
