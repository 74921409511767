'use client'

import axios from 'axios'
import React, { useContext, useState } from 'react'
import Form from '@/components/Form'
import Toast from '@/contexts/ToastContext'
import Wallet from '@/contexts/WalletContext'
import { FormField, ProfileAuthorized } from '@/lib/types'

type AccountEmailSettingsProps = {
  profile: ProfileAuthorized
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getProfile?: () => void
}

export default function AccountEmailSettings({
  profile,
  setIsOpen,
  getProfile,
}: AccountEmailSettingsProps) {
  const { addToast } = useContext(Toast.Context)
  const { sessionSignature } = useContext(Wallet.Context)

  const emailSettingsFields: FormField[] = [
    {
      name: 'followerActivity',
      label: 'Follower Activity',
      hint: 'When someone follows you or purchases your release',
      type: 'select',
      placeholder: 'Select a frequency',
      required: false,
      value: profile?.emailNotificationNewActivity,
      inputProps: {
        inputSelectOptions: [
          {
            label: 'Immediately',
            value: 'immediately',
          },
          {
            label: 'Never',
            value: 'never',
          },
        ],
      },
    },
    {
      name: 'followingActivity',
      label: 'Following Activity',
      hint: 'When someone you follow publishes a release',
      type: 'select',
      placeholder: 'Select a frequency',
      required: false,
      value: profile?.emailNotificationNewActivityFollowing,
      inputProps: {
        inputSelectOptions: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Never',
            value: 'never',
          },
        ],
      },
    },
  ]

  const [fields, setFields] = useState(emailSettingsFields)

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLSelectElement
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        if (field.name === target.name) {
          return { ...field, value: target.value }
        }

        return field
      })

      return updatedFields
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault()
      const formData = new FormData()

      if (sessionSignature) {
        formData.append('publicKey', sessionSignature?.publicKey)
        formData.append('signature', sessionSignature?.signature)
        formData.append('message', sessionSignature?.message)
      }

      if (fields[0].value !== profile?.emailNotificationNewActivity) {
        formData.append('emailNotificationNewActivity', fields[0].value)
      }

      if (fields[1].value !== profile?.emailNotificationNewActivityFollowing) {
        formData.append(
          'emailNotificationNewActivityFollowing',
          fields[1].value,
        )
      }

      await axios.put(`${process.env.NINA_ID_ENDPOINT}/profile`, formData)

      if (getProfile) {
        getProfile()
      }

      setIsOpen(false)
      addToast({
        message: 'Changes saved successfully',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      addToast({
        message: 'Error saving email preferences',
        variant: 'error',
      })
    }
  }

  return (
    <div className="mt-30">
      <p className="body-1 text-grey-45">
        Select the activities you wish to receive email notifications for.
      </p>
      <div className="mt-28 ">
        <Form
          fields={fields as FormField[]}
          buttonLabel="Save email settings"
          setFields={setFields}
          handleCancel={() => setIsOpen(false)}
          handleSubmit={(e) => handleSubmit(e)}
          handleChange={handleChange}
          allowCancel={true}
          inSettings={true}
        />
      </div>
    </div>
  )
}
