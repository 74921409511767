'use client'

import { clsx } from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { memo, useContext, useMemo, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  FilterType,
  ModalType,
  SupplementalTabContent,
} from '@/lib/types'
import { camelCaseToUi } from '@/lib/utils/camelCaseToUi'
import { logEvent } from '@/lib/utils/event'
import Modal from './Modal'
import Button from './tokens/Button'

interface FilterObject {
  label: FilterType
  filterValues: { label: string; value: string }[]
}

export type TabProps = {
  content: object
  supplementalContent?: SupplementalTabContent
  filterOptions?: FilterObject[]
  inSettings?: boolean
  contentHeaderTotals?: { [key: string]: number | undefined }
}

function Tabs({
  content,
  supplementalContent,
  inSettings = false,
  contentHeaderTotals,
}: TabProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const headers = useMemo(() => Object.keys(content), [content])
  const contentArray = useMemo(() => Object.values(content), [content])
  const [activeTab, setActiveTab] = useState(0)
  const activeTabName = headers[activeTab]
  const pathname = usePathname()
  const { wallet } = useContext(Wallet.Context)

  const handleClick = (index: number) => {
    setActiveTab(index)

    const tab = headers[index]
    logEvent('tab_clicked', 'interaction', wallet, { tab, pathname })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div
          className={clsx(
            !inSettings && 'md:border-b-1 md:border-solid md:border-grey-10',
            'relative mb-20 flex w-full md:mb-28 ',
          )}
        >
          <div className="no-scrollbar absolute bottom-[-1px] ml-[-10px] w-[99vw] overflow-x-auto pl-10 md:ml-0 md:w-full md:pl-0">
            <div className="flex">
              {headers.map((header, index) => {
                let total

                if (
                  contentHeaderTotals &&
                  contentHeaderTotals[header] !== undefined
                ) {
                  total = contentHeaderTotals[header]!
                }

                return (
                  <>
                    <button
                      key={index}
                      onClick={() => handleClick(index)}
                      className={clsx(
                        activeTab === index && 'border-b-1 !border-black ',
                        'border-b-1 border-white md:border-grey-10',
                        'mr-[16px]  md:mr-[20px]',
                        inSettings && '!mr-[16px] md:border-white',
                        index === headers.length - 1 && 'mr-[7px]',
                      )}
                    >
                      <h1
                        key={index}
                        className={clsx(
                          'ui-1 whitespace-nowrap pb-[2px] md:pb-8 md:hover:opacity-70',
                          activeTab === index && 'md:hover:!opacity-100',
                          inSettings && ' md:pb-[2px]',
                        )}
                      >
                        {' '}
                        {camelCaseToUi(header)}
                        {total === 0 && ' (0)'}
                        {total !== undefined && total > 0 && ` (${total})`}
                      </h1>
                    </button>
                    {/* forcing white space on right for mobile */}
                    {index === headers.length - 1 && (
                      <div className=" w-[6px]  opacity-0 md:hidden">
                        <p>.</p>
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
          {supplementalContent && supplementalContent[activeTabName] && (
            <div className="ui-1 border-3 absolute bottom-[-1px] right-0 top-[-24px]  hidden md:top-[unset] md:!flex md:pb-[9px]">
              {supplementalContent[activeTabName].modalType && (
                <Modal
                  modalType={
                    supplementalContent[activeTabName].modalType ||
                    ModalType.Default
                  }
                  label={supplementalContent[activeTabName].label}
                  modalLabelVariant={ButtonVariant.Unstyled}
                  modalLabelTextColor={ButtonColor.Grey}
                  modalLabelFont={ButtonFont.UI1}
                  isOpen={isOpen}
                  modalHeader={supplementalContent[activeTabName].modalHeader}
                  searchInput={supplementalContent[activeTabName].searchInput}
                  searchInputPlaceholder={
                    supplementalContent[activeTabName].searchInputPlaceholder
                  }
                  setIsOpen={setIsOpen}
                >
                  {supplementalContent[activeTabName].content}
                </Modal>
              )}
              {supplementalContent[activeTabName].href && (
                <div className="ui-1 absolute bottom-[-1px] right-0  pb-4 md:pb-[9px]">
                  <Link href={supplementalContent[activeTabName].href || ''}>
                    <Button
                      variant={ButtonVariant.Unstyled}
                      textColor={ButtonColor.Grey}
                      font={ButtonFont.UI1}
                      label={supplementalContent[activeTabName].label}
                    />
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        {contentArray.map((content, index) => {
          return (
            <div
              key={index}
              className={clsx(activeTab === index ? 'block' : 'hidden')}
            >
              {content}
            </div>
          )
        })}
      </div>
      {supplementalContent && supplementalContent[activeTabName] && (
        <div className="block w-full md:!hidden">
          {supplementalContent[activeTabName].modalType && (
            <div className="mt-20">
              <Modal
                modalType={
                  supplementalContent[activeTabName].modalType ||
                  ModalType.Default
                }
                label={supplementalContent[activeTabName].label}
                modalLabelVariant={ButtonVariant.Small}
                modalLabelFont={ButtonFont.Body1}
                modalHeader={supplementalContent[activeTabName].modalHeader}
                modalLabelTextColor={ButtonColor.Grey}
                modalLabelColor={ButtonColor.Grey}
                searchInput={supplementalContent[activeTabName].searchInput}
                searchInputPlaceholder={
                  supplementalContent[activeTabName].searchInputPlaceholder
                }
                isOpen={isMobileOpen}
                setIsOpen={setIsMobileOpen}
                disableKeydownSubmit={true}
              >
                {supplementalContent[activeTabName].content}
              </Modal>
            </div>
          )}
          {supplementalContent[activeTabName].href && (
            <div className="mt-20">
              <Link
                href={supplementalContent[activeTabName].href || ''}
                passHref
              >
                <Button
                  variant={ButtonVariant.Large}
                  textColor={ButtonColor.Grey}
                  color={ButtonColor.Grey}
                  font={ButtonFont.Body1}
                  label={supplementalContent[activeTabName].label}
                  responsiveness={ButtonResponsiveness.Full}
                />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(Tabs)
