'use client'

import axios from 'axios'
import { useRouter } from 'next/navigation'
import React, { useContext, useRef, useState } from 'react'
import { Area } from 'react-easy-crop'
import { z } from 'zod'
import Form from '@/components/Form'
import PopUpContext from '@/contexts/PopUpContext'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import {
  Account,
  FormField,
  ImageCropProps,
  ProfileAuthorized,
} from '@/lib/types'
import getCroppedImage from '@/lib/utils/cropImage'
import {
  formatEmailString,
  formatHandleString,
} from '@/lib/utils/formatInputString'
import { validateField } from '@/lib/utils/validateField'
import { validateFormSchema } from '@/lib/utils/validateFormSchema'

type AccountProfileSettingsProps = {
  profile: ProfileAuthorized | Account
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getProfile?: () => void
}

type FormFieldNoValue = Omit<FormField, 'value'>

type ImageFieldProps = FormFieldNoValue & {
  value: ImageCropProps
}

export default function AccountProfileSettings({
  profile,
  setIsOpen,
  getProfile,
}: AccountProfileSettingsProps) {
  const imageFieldRef = useRef<HTMLInputElement>(null)
  const { addToast } = useContext(Toast.Context)
  const { sessionSignature } = useContext(Wallet.Context)
  const [submitting, setSubmitting] = useState(false)
  const { update } = useContext(Update.Context)
  const { setForceOpen } = useContext(PopUpContext.Context)
  const router = useRouter()
  const inProfilePage = document.URL.includes('/profiles/')

  const accountFields: FormField[] = [
    {
      name: 'image',
      label: 'Image',
      type: 'imageUpload',
      value: profile?.image,
      required: false,
      inputProps: {
        fileSizeLimit: 5000000,
        inputRef: imageFieldRef,
      },
    },
    {
      name: 'handle',
      label: 'Handle',
      type: 'text',
      placeholder: 'ninaprotocol.com/profiles/your-handle-here',
      required: true,
      value: profile?.handle,
      inputProps: {
        max: 18,
      },
    },
    {
      name: 'displayName',
      label: 'Display Name',
      type: 'text',
      placeholder: 'Enter a display name',
      required: true,
      value: profile?.displayName,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      placeholder: 'Enter an email',
      required: false,
      disabled:
        'emailIsVerified' in profile && profile?.emailIsVerified ? true : false,
      value: ('email' in profile && profile?.email) || '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      value: profile?.description,
      required: false,
    },
  ]

  const [fields, setFields] = useState<FormField[]>(accountFields)

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        if (field.name === target.name) {
          if (field.name === 'handle') {
            const updatedError = validateField(field, e)

            return {
              ...field,
              value: formatHandleString(target.value),
              error: updatedError,
            }
          }

          if (field.name === 'email') {
            return {
              ...field,
              value: formatEmailString(target.value),
            }
          }

          const updatedError = validateField(field, e)

          return { ...field, value: target.value, error: updatedError }
        }

        return field
      })

      return updatedFields
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    // const imageSchema = z.object({
    //   croppedAreaPixels: z.object({
    //     x: z.number(),
    //     y: z.number(),
    //     width: z.number(),
    //     height: z.number(),
    //   }),
    //   file: z.string(),
    // })

    const validations = {
      handle: z.string().min(1, 'Handle is required').max(255),
      displayName: z.string().min(1, 'Display Name is required').max(255),
      description: z
        .string()
        .max(255, 'Decription cannot be longer than 255 characters')
        .nullable(),
      // image: imageSchema.nullable(),
      email: z.string(),
    }

    const email = fields.find((field) => field.name === 'email')

    if (email && email.value != '') {
      validations.email = z.string().email('Invalid email address').max(255)
    }

    const validationSchema = z.object(validations)

    const validationResult = validateFormSchema(
      validationSchema,
      fields,
      setFields,
    )

    if (!validationResult.success) return

    try {
      e.preventDefault()
      setSubmitting(true)
      const imageFile = await imageCropToFile(profile.publicKey)
      const formData = new FormData()

      if (sessionSignature) {
        formData.append('publicKey', sessionSignature?.publicKey)
        formData.append('signature', sessionSignature?.signature)
        formData.append('message', sessionSignature?.message)
      }

      // TO DO: handle string format
      if (fields[1].value !== profile.handle) {
        formData.append('handle', fields[1].value)
      }

      if (fields[2].value !== profile.displayName) {
        formData.append('displayName', fields[2].value)
      }

      // TO DO: email string format
      if (
        'emailIsVerified' in profile &&
        !profile.emailIsVerified &&
        fields[3].value !== profile.email
      ) {
        formData.append('email', fields[3].value)
      }

      if (fields[4].value !== profile.description) {
        formData.append('description', fields[4].value)
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (imageFile?.file) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('image', imageFile?.file)
      }

      await axios.put(`${process.env.NINA_ID_ENDPOINT}/profile`, formData)
      setSubmitting(false)
      setIsOpen(false)
      setForceOpen(false)
      update()

      if (getProfile) {
        getProfile()
      }

      addToast({
        message: 'Changes saved successfully',
        variant: 'success',
      })

      if (fields[1].value !== profile.handle && inProfilePage) {
        router.push(`/profiles/${fields[1].value}`)
      }
    } catch (error) {
      console.error(error)
      setSubmitting(false)
      addToast({
        message: 'Error saving changes',
        variant: 'error',
      })
    }
  }

  const handleCroppedImage = (data: {
    imageSrc: string
    croppedAreaPixels: Area
    file: Blob | MediaSource | undefined | File
  }) => {
    if (
      data.croppedAreaPixels.x === 0 &&
      data.croppedAreaPixels.y === 0 &&
      !data.file
    ) {
      return
    }

    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]

      const profileImageField = prevFieldsCopy.find(
        (field) => field.name === 'image',
      )

      if (profileImageField) {
        profileImageField.value = data
      }

      return prevFieldsCopy
    })
  }

  const imageCropToFile = async (filename?: string) => {
    const imageField = fields?.find(
      (field: FormField) => field.name === 'image',
    ) as ImageFieldProps

    const { imageSrc, file, croppedAreaPixels } = imageField.value
    let croppedImageResult: string | undefined

    if (imageSrc && croppedAreaPixels) {
      croppedImageResult = (await getCroppedImage(
        imageSrc as string,
        croppedAreaPixels,
        filename,
        file?.type,
      )) as string

      setFields((prevFields) => {
        const prevFieldsCopy = [...prevFields]

        const imageField = prevFieldsCopy.find(
          (field) => field.name === 'image',
        )

        if (imageField?.value) {
          imageField.value.croppedImage = croppedImageResult
        }

        return prevFieldsCopy
      })
    }

    return croppedImageResult
  }

  const handleCancel = () => {
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        return { ...field, value: undefined }
      })

      return updatedFields
    })
    setIsOpen(false)
    setForceOpen(false)
  }

  const imageFieldReset = () => {
    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]
      const imageField = prevFieldsCopy.find((field) => field.name === 'image')

      if (imageField) {
        imageField.value = undefined
        imageField.error = undefined
      }

      return prevFieldsCopy
    })
  }

  return (
    <div className="no-scrollbar overflow-hidden">
      <Form
        fields={fields}
        setFields={setFields}
        buttonLabel="Save changes"
        title="Profile Settings"
        handleSubmit={(e) => handleSubmit(e)}
        handleChange={handleChange}
        handleCroppedImage={handleCroppedImage}
        isInModal={true}
        allowCancel={true}
        handleCancel={handleCancel}
        isRound={true}
        inSettings={true}
        disableSubmit={submitting}
        submittingLabel="Saving changes"
        imageFieldReset={imageFieldReset}
      />
    </div>
  )
}
